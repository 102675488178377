.form-wizard {
	text-align: left;
}

.form-wizard-searchBar {
	width:100%;
	padding-top: 1em;
}

//.form-wizard .field-column {
//	padding-left : 0px !important;
//	padding-right : 0px !important;
//}

.form-wizard {
	margin: 0px;
	padding: 0px;
}

    //@include media-breakpoint-up(md) {
	//
	//	//.form-wizard .field-column {
	//	//	margin: 0px;
	//	//	padding: 0px;
	//	//}
	//}

//.form-wizard > .col-md-12 {
//	margin: 0px;
//	padding: 0px;
//}

