.f-btn {
	border-radius: 0px;
	padding: 0px 1.5rem 0px 1.5rem;
	font-weight: bold;
	height:60px;
}

.f-btn-primary {
	background-color:$fiduc-button-active;
	color:$fiduc-slate;
	border-color: $fiduc-button-active;
}

.f-btn-primary:active, .f-btn-primary:hover, .f-btn-primary:focus {
	background-color:$fiduc-button-active;
	color:$fiduc-slate;
	border-color: $fiduc-button-active;
}

.f-btn-primary:disabled {
	color:$fiduc-slate;
	opacity: .4;
	border-color: $fiduc-button-active;
}

.f-btn-icon {
	border:0;
	background-color: transparent;
}

.f-btn-icon:active, .f-btn-icon:hover, .f-btn-icon:focus {
	font-weight: 600;
}

.f-btn-icon:disabled {
	color:$fiduc-slate;
	opacity: .4;
	font-weight: unset;
	cursor: unset;
}

.f-btn-icon-dashboard {
	border-radius: 1pt;
	color: #fff;
	cursor: pointer;
}

.f-btn-icon-dashboard.disabled {
	color:$fiduc-gray !important;
	opacity: .5 !important;
}

.f-btn-icon-dashboard .icon {
	background-color: $fiduc-neutral;
	border-radius: 2pt;
	transition: transform, color;
	transition-duration: .2s;
	transition-timing-function: cubic-bezier(.5,0,0,1), ease-in;
}

.f-btn-icon-dashboard:active .icon,
.f-btn-icon-dashboard:hover .icon,
.f-btn-icon-dashboard:focus .icon {
	background-color: $fiduc-button-active !important;
	transform:scale(1.10);
	color:#fff !important;
}

.f-btn-icon-dashboard:active .caption, .f-btn-icon-dashboard:hover .caption, .f-btn-icon-dashboard:focus .caption,
.f-btn-icon-dashboard:active .icon, .f-btn-icon-dashboard:hover .icon, .f-btn-icon-dashboard:focus .icon {
	color:#fff;
}

.f-btn-icon-dashboard .caption {
	position: relative;
	margin: auto;
}

.f-btn-icon-dashboard:active .caption, .f-btn-icon-dashboard:hover .caption, .f-btn-icon-dashboard:focus .caption {
	font-weight: 600;
  margin: auto;
}

.f-btn-icon-dashboard:active, .f-btn-icon-dashboard:hover, .f-btn-icon-dashboard:focus{
}

.f-btn-secondary {
	background-color:$white;
	color:$fiduc-slate;
	border-color: $white;
}

.f-btn-secondary:active, .f-btn-secondary:hover, .f-btn-secondary:focus {
	background-color:$fiduc-button-active;
	color:$fiduc-slate;
	border-color: $fiduc-button-active;
}

.f-btn-secondary:disabled {
	color:$fiduc-slate;
	opacity: .4;
}

.f-btn-medium {
	height:50px;
}
.f-btn-small {
	height:32px;
}



.f-link {
}


.f-link-menu LI {
	color:lighten($fiduc-black, 10);
}

.f-link-menu:hover LI, .f-link-menu:active LI, .f-link-menu:focus LI {
	color:darken($fiduc-black, 10);
}

.f-link-menu  {
	color:lighten($fiduc-black, 10);
	border-color: $fiduc-button-active;
	background-color: $white;
}

.f-link-menu:active, .f-link-menu:hover, .f-link-menu:focus {
	color:darken($fiduc-black, 10);
	border-color: $fiduc-button-active;
	background-color: $fiduc-button-active;
}

.f-link-menu:disabled {
	color:$fiduc-slate;
	opacity: .4;
	background-color: $white;
	border-color: $white;
}

.f-link-transparent {
	background-color: transparent;
}

.f-link-light {
	color:$white;
	border-color: $fiduc-button-active;
}

.f-link-light:active, .f-link-light:hover, .f-link-light:focus {
	color:$white;
	font-weight: 900;
	border-color: $fiduc-button-active;
}

.f-link-light:disabled {
	color:$fiduc-gray;
	opacity: .4;
	border-color: $fiduc-button-active;
}

li.mdi  {
	list-style: none;
}
li.di  {
	list-style: none;
}
