
$font-weight-list: (
	100: "Ultralight",
	200: "Ultralight",
	300: "Light",
	400: "Regular",
	500: "Regular",
	600: "Medium",
	700: "Bold",
	800: "Ultrabold",
	900: "Black",
);

@each $weight, $name in $font-weight-list {
	@font-face
	{
		font-family: 'PP Neue Machina';
		font-weight: $weight;
		font-style: normal;
		//font-display: block;

		src: url('../../fonts/PPNeueMachina/PPNeueMachina-#{$name}.eot');
		src: url('../../fonts/PPNeueMachina/PPNeueMachina-#{$name}.eot') format('embedded-opentype'),
		url('../../fonts/PPNeueMachina/PPNeueMachina-#{$name}.woff2') format('woff2'),
		url('../../fonts/PPNeueMachina/PPNeueMachina-#{$name}.woff') format('woff'),
		url('../../fonts/PPNeueMachina/PPNeueMachina-#{$name}.ttf') format('truetype');
	}

	.machina-#{$weight} {
		font-weight :$weight;
	}
}

@font-face {
	font-family: 'password';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	//src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
	src: url('../../fonts/Password/password.ttf') format('truetype');
}


.font-family-title {
	font-family: "PP Neue Machina" , Courier, Monaco, monospace !important;
}

BODY {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Roboto, Arial, "Lucida Grande", sans-serif;
	 //font-family: "Helvetica Neue", "Helvetica", "Roboto";
	//font-family: "Arial Black";
}


//FIX BUG WEBVIEW NOT DISPLAYING SVG.text with filters
.apexcharts-text {
	filter:none !important;
}


.font-family-body {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Roboto, Arial, "Lucida Grande", sans-serif;
}

.font-weight-100 {font-weight: 100;}
.font-weight-200 {font-weight: 200;}
.font-weight-300 {font-weight: 300;}
.font-weight-400 {font-weight: 400;}
.font-weight-500 {font-weight: 500;}
.font-weight-600 {font-weight: 600;}
.font-weight-700 {font-weight: 700;}
.font-weight-800 {font-weight: 800;}
.font-weight-900 {font-weight: 900;}

.cp-title-right {
	font-size:2.7vh;
	margin-right: 0rem;
}
.cp-title-left { font-size:2.4vh }
.cp-text-left { font-size:2.0vh }

.font-size-em-1 { font-size: 0.8em !important;}
.font-size-em-2 { font-size: 0.7em !important;}
.font-size-em-3 { font-size: 0.6em !important;}
.font-size-em-4 { font-size: 0.5em !important;}
.font-size-em-5 { font-size: 0.4em !important;}
.font-size-em-6 { font-size: 0.3em !important;}
.font-size-em-7 { font-size: 0.2em !important;}

.font-size-em0 { font-size: 1em !important;}
.font-size-em1 { font-size: 1.2em !important;}
.font-size-em2 { font-size: 1.3em !important;}
.font-size-em3 { font-size: 1.4em !important;}
.font-size-em4 { font-size: 1.5em !important;}
.font-size-em5 { font-size: 1.6em !important;}
.font-size-em6 { font-size: 1.7em !important;}
.font-size-em7 { font-size: 1.8em !important;}
.font-size-em8 { font-size: 1.9em !important;}
.font-size-em9 { font-size: 2.0em !important;}
.font-size-em10{ font-size: 2.2em !important;}
.font-size-em11{ font-size: 2.4em !important;}
.font-size-em12{ font-size: 2.6em !important;}
.font-size-em13{ font-size: 2.8em !important;}
.font-size-em14{ font-size: 3.0em !important;}

.font-size-rem-1 { font-size: 0.9rem !important;}
.font-size-rem-2 { font-size: 0.8rem !important;}
.font-size-rem-3 { font-size: 0.7rem !important;}
.font-size-rem-4 { font-size: 0.6rem !important;}
.font-size-rem-5 { font-size: 0.5rem !important;}
.font-size-rem-6 { font-size: 0.4rem !important;}
.font-size-rem-7 { font-size: 0.3rem !important;}
.font-size-rem-8 { font-size: 0.2rem !important;}

.font-size-rem0 { font-size: 1rem !important;}
.font-size-rem1 { font-size: 1.1rem !important;}
.font-size-rem2 { font-size: 1.2rem !important;}
.font-size-rem3 { font-size: 1.3rem !important;}
.font-size-rem4 { font-size: 1.4rem !important;}
.font-size-rem5 { font-size: 1.5rem !important;}
.font-size-rem6 { font-size: 1.6rem !important;}
.font-size-rem7 { font-size: 1.7rem !important;}
.font-size-rem8 { font-size: 1.8rem !important;}
.font-size-rem9 { font-size: 2.0rem !important;}
.font-size-rem10{ font-size: 2.2rem !important;}
.font-size-rem11{ font-size: 2.4rem !important;}
.font-size-rem12{ font-size: 2.6rem !important;}
.font-size-rem13{ font-size: 2.8rem !important;}
.font-size-rem14{ font-size: 3.0rem !important;}
.font-size-rem15{ font-size: 3.5rem !important;}
.font-size-rem16{ font-size: 3.0rem !important;}
.font-size-rem17{ font-size: 3.0rem !important;}
.font-size-rem18{ font-size: 3.0rem !important;}
.font-size-rem19{ font-size: 3.0rem !important;}
.font-size-rem20{ font-size: 3.0rem !important;}


.font-size-pt-5 { font-size: 5pt !important;}
.font-size-pt-4 { font-size: 6pt !important;}
.font-size-pt-3 { font-size: 7pt !important;}
.font-size-pt-2 { font-size: 8pt !important;}
.font-size-pt-1 { font-size: 9pt !important;}
.font-size-pt0 { font-size: 12pt !important;}
.font-size-pt1 { font-size: 13pt !important;}
.font-size-pt2 { font-size: 14pt !important;}
.font-size-pt3 { font-size: 15pt !important;}
.font-size-pt4 { font-size: 16pt !important;}
.font-size-pt5 { font-size: 17pt !important;}
.font-size-pt6 { font-size: 18pt !important;}
.font-size-pt7 { font-size: 19pt !important;}
.font-size-pt8 { font-size: 20pt !important;}
.font-size-pt9 { font-size: 21pt !important;}
.font-size-pt10 { font-size: 20pt !important;}
.font-size-pt11 { font-size: 22pt !important;}
.font-size-pt12 { font-size: 24pt !important;}
.font-size-pt13 { font-size: 26pt !important;}
.font-size-pt14 { font-size: 28pt !important;}
.font-size-pt15 { font-size: 30pt !important;}
.font-size-pt16 { font-size: 32pt !important;}
.font-size-pt17 { font-size: 34pt !important;}
.font-size-pt18 { font-size: 36pt !important;}
.font-size-pt19 { font-size: 38pt !important;}
.font-size-pt20 { font-size: 40pt !important;}

.font-intro-title-small {
	font-family: "PP Neue Machina";
	font-weight: 500;
	font-size:1.4rem;
	@media (max-width: 720px) {
		font-size:1rem;
		font-weight: 300;
	}
}
.font-intro-title-big {
	font-family: "PP Neue Machina";
	font-weight: 600;
	font-size:2.4rem;
	@media (max-width: 720px) {
		font-size:1.3rem;
	}
}
